// CreateEmployee.js
import React from 'react'
import EmployeeForm from './EmployeeForm'
import { Grid } from '@mui/material'

const CreateEmployee = () => {
  return (
    <div className="agregarEmpleado" style={{ padding: '30px' }}>
      <h2>Agregar nuevo empleado</h2>
      <br></br>
      <Grid item xs={12}>
        <EmployeeForm />
      </Grid>
    </div>
  )
}

export default CreateEmployee
