import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Sidebar from './components/Sidebar'
import InicioPage from './components/InicioPage'
import CreateEmployee from './components/CreateEmployee'
import LoginPage from './components/LoginPage'
import RegisterPage from './components/RegisterPage' // Agrega la importación de la página de registro

const App = () => {
  const storedIsAuthenticated =
    localStorage.getItem('isAuthenticated') === 'true'
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />
  }

  return (
    <Router>
      <div>
        <Sidebar
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
        <Routes>
          <Route path="/" element={<Navigate to="/listado" />} />
          <Route
            path="/listado"
            element={<PrivateRoute element={<InicioPage />} />}
          />
          <Route
            path="/crear-empleado"
            element={<PrivateRoute element={<CreateEmployee />} />}
          />
          <Route
            path="/login"
            element={
              <LoginPage
                setIsAuthenticated={setIsAuthenticated}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/registro"
            element={<RegisterPage setIsAuthenticated={setIsAuthenticated} />}
          />

          {/* Agrega la ruta de la página de registro */}
        </Routes>
      </div>
    </Router>
  )
}

export default App
