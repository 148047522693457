import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Tooltip,
} from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import EventNoteIcon from '@mui/icons-material/EventNote'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Calendar } from 'react-multi-date-picker'
import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Menu from '@mui/material/Menu'
import Drawer from '@mui/material/Drawer'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Cambia el valor a '100%'
  height: '90%', // Cambia el valor a '100%'
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const InicioPage = () => {
  const [openHomeOfficeModal, setOpenHomeOfficeModal] = useState(false)
  const [selectedHomeOfficeDays, setSelectedHomeOfficeDays] = useState([])
  const [diasHomeOfficeTotal, setDiasHomeOfficeTotal] = useState(0)

  const [existingHomeOfficeDates, setExistingHomeOfficeDates] = useState([])
  const [employeeHomeOfficeDates, setEmployeeHomeOfficeDates] = useState(
    existingHomeOfficeDates,
  )

  const [diasTotalesLeyHomeOffice, setDiasTotalesHomeOffice] = useState(null)

  const [estadoEdicionEmpleado, setEstadoEdicionEmpleado] = useState(true)

  const [noVacacionesHoy, setNoVacacionesHoy] = useState(true)

  const handleEditarEmpleado = () => {
    setEstadoEdicionEmpleado(false)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const openDropDown = Boolean(anchorEl)
  const handleClickDropDown = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseDropDown = () => {
    setAnchorEl(null)
  }

  const [state, setState] = React.useState({
    left: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 'auto' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <h3>{selectedEmployee && selectedEmployee.name}</h3>
      <Grid container xs={12}>
        <Grid item xs={12} md={4}>
          <br />
          <Grid container xs={12}>
            <span className="textoVacExtr">Vacaciones Asignadas Extra:</span>
            <b>
              <Grid item className="contenedorDiasExtrasCantidad" xs={12}>
                <h4 className="diasExtrasVacaciones">
                  {vacacionesExtraHook != 0 ? vacacionesExtraHook : 0}
                </h4>
              </Grid>
            </b>
          </Grid>
          <br></br>
          <Grid container xs={12}>
            <span className="textoVacExtr">Vacaciones por ley:</span>
            <b>
              <Grid item className="contenedorDiasExtrasCantidad" xs={12}>
                <h4 className="diasExtrasVacaciones">
                  {diasTotalesLeyVacaciones > 0 ? diasTotalesLeyVacaciones : 0}
                </h4>
              </Grid>
            </b>
          </Grid>
          <br></br>
          <Grid container xs={12}>
            <span className="textoVacExtr">Vacaciones Totales:</span>
            <b>
              <Grid item className="contenedorDiasExtrasCantidad" xs={12}>
                <h4 className="diasExtrasVacaciones">
                  {Number(diasTotalesLeyVacaciones) +
                    Number(vacacionesExtraHook) -
                    Number(employeeVacationDatesVisual.length)}
                </h4>
              </Grid>
            </b>
          </Grid>
          <br></br>
          <br></br>
          <div style={{ marginBottom: '20px' }}>
            <Typography variant="body1" gutterBottom>
              Selecciona las nuevas fechas de vacaciones:
            </Typography>
            <Calendar
              value={selectedVacationDays}
              onChange={(value) => {
                const unselectableDates = employeeVacationDates.map(
                  (date) => +new Date(date),
                )

                // Filtrar solo las fechas de la base de datos
                const databaseDates = selectedVacationDays.filter((date) =>
                  unselectableDates.includes(+date),
                )

                // Filtrar las nuevas fechas seleccionadas
                const newSelectableDates = value.filter(
                  (date) =>
                    !unselectableDates.includes(+date) && !isDateDisabled(date),
                )

                const newSelectableDatesVisuales = newSelectableDates.map(
                  (date) => +new Date(date),
                )
                setSelectedVacationDaysNews(
                  newSelectableDatesVisuales.sort(
                    (a, b) => moment(a) - moment(b),
                  ),
                )

                // Actualizar el estado solo con las fechas de la base de datos y las nuevas fechas seleccionadas

                setSelectedVacationDays([
                  ...databaseDates,
                  ...newSelectableDates,
                ])
              }}
              multiple
              defaultValue={employeeVacationDates.map((date) =>
                moment(new Date(date)).format('DD/MM/YYYY'),
              )}
              isDateDisabled={isDateDisabled}
              mapDays={({ date, view }) => {
                let props = {}

                if (date < new Date()) {
                  props.className = 'highlight highlight-green' // Puedes ajustar la clase según tus necesidades
                }

                return props
              }}
            />
            <br></br>
            <div>
              <p>Agregar dias extra de vacaciones</p>
              <Grid container xs={12}>
                <b>
                  <Grid item className="" xs={12}>
                    <TextField
                      type="number"
                      className=""
                      variant="outlined"
                      size="small"
                      value={diasGanados}
                      onChange={handleChange}
                      InputProps={{ readOnly: true }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDecrement}
                      style={{ marginRight: '10px' }}
                    >
                      -
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleIncrement}
                    >
                      +
                    </Button>
                  </Grid>
                </b>
              </Grid>
            </div>
            <br></br>
            <Grid container justify="center">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span className="textoVacExtr">
                  Despues de estos cambios quedan:
                </span>
                <br></br>
                <Grid
                  item
                  className="contenedorDiasExtrasCantidad diasExtrasVacaciones diasExtrasVacacionesTotalActual"
                  xs={12}
                >
                  <h4 className="">
                    {Number(diasTotalesLeyVacaciones) +
                      Number(vacacionesExtraHook) -
                      Number(selectedVacationDays.length) +
                      Number(diasGanados)}
                  </h4>
                </Grid>
                <h5
                  style={{
                    marginLeft: '4px',
                  }}
                >
                  Dias
                </h5>
              </div>
            </Grid>
            <br></br>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ marginTop: '10px' }}>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  <strong>Nuevos dias seleccionados:</strong>
                </Typography>

                {selectedVacationDaysNews.map((date) => {
                  return (
                    <div style={{ display: 'flex', marginBottom: '5px' }}>
                      <div className="contenedorWarning">
                        {employeeList.some((employee) =>
                          employee.vacaciones?.some(
                            (vacacion) =>
                              moment(vacacion).format('YYYY-MM-DD') ===
                              moment(date).format('YYYY-MM-DD'),
                          ),
                        ) && (
                          <Tooltip
                            title={
                              <>
                                <div>
                                  <h2>
                                    Las siguientes personas tienen vacaciones el
                                    mismo día:{' '}
                                    {moment(date).format('DD/MM/YYYY')}
                                  </h2>
                                  <br />
                                </div>
                                {employeeList
                                  .filter((employee) =>
                                    employee.vacaciones?.some(
                                      (vacacion) =>
                                        moment(vacacion).format(
                                          'YYYY-MM-DD',
                                        ) === moment(date).format('YYYY-MM-DD'),
                                    ),
                                  )
                                  .map((employee) => (
                                    <div key={employee._id}>
                                      <h2>
                                        <p>- {employee.name}</p>
                                      </h2>
                                    </div>
                                  ))}
                              </>
                            }
                          >
                            <span style={{ marginLeft: '5px' }}>
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                style={{
                                  color: 'orange',
                                  fontSize: '24px',
                                  cursor: 'pointer',
                                  marginTop: '3px',
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <span
                        style={{
                          display: 'flex',
                          width: '120px',
                          alignItems: 'center',
                          borderRadius: '5px',
                          backgroundColor: '#1976d2',
                          border: 'solid 1px',
                          padding: '3px 10px',
                          color: '#fff',
                        }}
                      >
                        {moment(date).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  )
                })}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  <strong>Vacaciones agendadas pendientes:</strong>
                </Typography>
                {employeeVacationDates
                  .filter((date) => moment(date).isSameOrAfter(moment(), 'day'))
                  .map((date) => {
                    let hasEmployeesWithSameDate
                    if (selectedEmployee && selectedEmployee._id) {
                      hasEmployeesWithSameDate = employeeList.some(
                        (employee) =>
                          employee._id !== selectedEmployee._id &&
                          employee.vacaciones?.some(
                            (vacacion) =>
                              moment(vacacion).format('YYYY-MM-DD') ===
                              moment(date).format('YYYY-MM-DD'),
                          ),
                      )
                      // Resto del código...
                    } else {
                      console.error(
                        'selectedEmployee no está definido o no tiene _id',
                      )
                      // Manejar el caso donde selectedEmployee no está definido
                    }

                    return (
                      <div
                        key={date}
                        style={{
                          display: 'flex',
                          marginBottom: '5px',
                        }}
                      >
                        <div className="contenedorWarning">
                          {hasEmployeesWithSameDate && (
                            <Tooltip
                              title={
                                <>
                                  <div>
                                    <h2>
                                      Las siguientes personas tienen vacaciones
                                      el mismo día:{' '}
                                      {moment(date).format('DD/MM/YYYY')}
                                    </h2>
                                    <br />
                                  </div>
                                  {employeeList
                                    .filter((employee) =>
                                      employee.vacaciones?.some(
                                        (vacacion) =>
                                          moment(vacacion).format(
                                            'YYYY-MM-DD',
                                          ) ===
                                          moment(date).format('YYYY-MM-DD'),
                                      ),
                                    )
                                    .map((employee) => {
                                      // Agrega la condición para evitar mostrar el nombre del empleado cargado
                                      if (
                                        employee._id !== selectedEmployee._id
                                      ) {
                                        return (
                                          <div key={employee._id}>
                                            <h2>
                                              <p>- {employee.name}</p>
                                            </h2>
                                          </div>
                                        )
                                      }
                                      return null
                                    })}
                                </>
                              }
                            >
                              <span style={{ marginLeft: '5px' }}>
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  style={{
                                    color: 'orange',
                                    marginRight: '10px',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    marginTop: '3px',
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </div>
                        <span
                          style={{
                            display: 'flex',
                            width: '120px',
                            alignItems: 'center',
                            borderRadius: '5px',
                            backgroundColor: '#1976d2',
                            border: 'solid 1px',
                            padding: '3px 10px',
                            color: '#fff',
                          }}
                        >
                          {moment(date).format('DD/MM/YYYY')}
                        </span>
                        <ClearIcon
                          style={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                            marginTop: '4px',
                          }}
                          onClick={() => {
                            handleDeleteVacationDate(date)
                            // Elimina la fecha del estado empleadoVacationDates
                            const updatedEmployeeVacationDates =
                              employeeVacationDates.filter(
                                (selectedDate) =>
                                  !moment(selectedDate).isSame(date, 'day'),
                              )
                            setEmployeeVacationDates(
                              updatedEmployeeVacationDates,
                            )
                          }}
                        />
                      </div>
                    )
                  })}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  <strong>Vacaciones agendadas tomadas:</strong>
                </Typography>
                {employeeVacationDates
                  .filter((date) => moment(date).isBefore(moment(), 'day'))
                  .map((date) => (
                    <div
                      key={date}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        marginBottom: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      {moment(date).format('DD/MM/YYYY')}
                    </div>
                  ))}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent="flex-end">
        <Button
          onClick={handleSaveVacations}
          variant="contained"
          color="primary"
        >
          Guardar
        </Button>
        <Button
          onClick={handleCloseNewModal}
          style={{ marginLeft: '10px' }}
          variant="outlined"
        >
          Cerrar
        </Button>
      </Grid>
    </Box>
  )

  const handleOpenHomeOfficeModal = async (employee) => {
    setSelectedEmployee(employee)

    // Recuperar las fechas de homeOffice del empleado desde el backend

    try {
      const response = await fetch(`/api/get-employee-details/${employee._id}`)
      if (response.ok) {
        const data = await response.json()
        console.log('data home office', data)
        const homeOfficeDates = data.employee.homeOffice || []
        setEmployeeHomeOfficeDates(homeOfficeDates)

        // Mantener las fechas ya seleccionadas y agregar las fechas de homeOffice
        setSelectedHomeOfficeDays((prevSelected) => [
          ...prevSelected,
          ...homeOfficeDates.map((date) => new Date(date)),
        ])
        console.log('DATAAA total empleado', data)
      } else {
        console.error(
          'Error al obtener las fechas de homeOffice del empleado:',
          response.statusText,
        )
        console.error(
          'Error al obtener las fechas de homeOffice del empleado:',
          response.statusText,
        )
      }
    } catch (error) {
      console.error('Error al realizar la solicitud al backend:', error)
    }

    setOpenHomeOfficeModal(true)
  }

  const handleCloseHomeOfficeModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenHomeOfficeModal(false)
    }
    setSelectedHomeOfficeDays([])
    setExistingHomeOfficeDates([]) // Restablecer las fechas existentes
  }

  const handleDeleteHomeOfficeDate = (date) => {
    // Filtrar las fechas seleccionadas y actualizar el estado
    const updatedSelectedHomeOfficeDays = selectedHomeOfficeDays.filter(
      (selectedDate) => !moment(selectedDate).isSame(date, 'day'),
    )
    setSelectedHomeOfficeDays(updatedSelectedHomeOfficeDays)
  }

  const handleSaveHomeOffices = async () => {
    const formattedHomeOfficeDays = selectedHomeOfficeDays.map((date) =>
      new Date(date).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }),
    )

    if (selectedEmployee) {
      const updatedEmployeeData = {
        ...selectedEmployee,
        diasHomeOfficeTotal: diasHomeOfficeTotal.toString(),
        homeOffice: formattedHomeOfficeDays,
      }

      const employeeId = selectedEmployee._id.toString()

      try {
        const response = await fetch(
          `/api/update-employee-homeoffice/${employeeId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedEmployeeData),
          },
        )

        if (response.ok) {
          console.log('La solicitud al backend fue exitosa')

          // Cierra el modal antes de mostrar el SweetAlert
          handleCloseHomeOfficeModal()

          // Muestra el SweetAlert de éxito con botón "OK"
          await MySwal.fire({
            icon: 'success',
            title: 'Fechas de Home Office guardadas correctamente',
            showConfirmButton: true,
          })

          // Recargar la lista de empleados después de la edición (opcional)
          fetchEmployeeData()
        } else {
          console.error('Error al actualizar el empleado:', response.statusText)
          throw new Error('Error al actualizar el empleado')
        }
      } catch (error) {
        console.error('Error al realizar la solicitud al backend:', error)

        // Muestra el SweetAlert de error con botón "OK"
        await MySwal.fire({
          icon: 'error',
          title: 'Error al actualizar el empleado',
          showConfirmButton: true,
        })

        console.log('After fetching data from the backend')
      }
    }
  }

  const [selectedCenter, setSelectedCenter] = useState('') // Define el estado para el centro seleccionado
  const [centers, setCenters] = useState([]) // Define el estado para la lista de centros

  useEffect(() => {
    const fetchUserCenter = async () => {
      // Lógica para obtener el centro asignado al usuario desde la base de datos
      try {
        const response = await fetch('/api/centers')
        const data = await response.json()
        if (data && data.centerNumber) {
          // Establece el centro del usuario como preseleccionado en el estado
          setSelectedCenter(data.centerNumber)
          // Actualiza el campo centerNumber en el estado de edición del empleado
          setEditedEmployeeData((prevData) => ({
            ...prevData,
            centerNumber: data.centerNumber,
          }))
        }
      } catch (error) {
        console.error('Error al obtener el centro asignado al usuario:', error)
      }
    }

    const fetchCenters = async () => {
      try {
        const response = await fetch('/api/centers')
        const data = await response.json()
        // Update the state variable 'centers' with the fetched data
        setCenters(data)
      } catch (error) {
        console.error('Error al obtener la lista de centros:', error)
      }
    }

    fetchUserCenter()
    fetchCenters() // Add this line to fetch the list of centers
  }, [])

  const MySwal = withReactContent(Swal)

  const gerenteInfo = JSON.parse(localStorage.getItem('gerente'))

  const [openNewModal, setOpenNewModal] = React.useState(false)
  const [selectedVacationDays, setSelectedVacationDays] = useState([])

  const [selectedVacationDaysNews, setSelectedVacationDaysNews] = useState([])

  const [existingVacationDates, setExistingVacationDates] = useState([])
  const [employeeVacationDates, setEmployeeVacationDates] = useState(
    existingVacationDates,
  )

  const [employeeVacationDatesVisual, setEmployeeVacationDatesVisual] =
    useState([employeeVacationDates])

  const [diasTotalesLeyVacaciones, setDiasTotalesLeyVacaciones] = useState(null)
  const [diasTotalesDisponibles, setDiasTotalesDisponibles] = useState(null)
  const [vacacionesExtraHook, setVacacionesExtraHook] = useState(null)

  const [diasGanados, setDiasGanados] = useState(null)

  const handleIncrement = () => {
    setDiasGanados(diasGanados + 1)
  }

  const handleDecrement = () => {
    setDiasGanados((prevDiasGanados) => prevDiasGanados - 1)
  }

  const handleChange = (event) => {
    const inputValue = parseInt(event.target.value, 10)

    if (!isNaN(inputValue)) {
      setDiasGanados(inputValue)
    }
  }

  const handleOpenNewModal = async (employee) => {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    setSelectedEmployee(employee)
    // SE RECUPERAN TODOS LOS EMPLEADOS PARA PODER OBTENER SUS VACACIONES Y VER SI COINCIDEN
    try {
      const gerenteId = JSON.parse(localStorage.getItem('gerente')).id
      const listResponse = await fetch(`/api/empleados?gerenteId=${gerenteId}`)
      const listData = await listResponse.json()
      setEmployeeList(listData.employees)
      console.log('SE OBTIENEN TODOS LOS EMPLEADOS DE ESTE GERENTE:', listData)
    } catch (error) {
      console.error('Error al obtener la información de inicio:', error)
      Swal.close() // Cerrar Swal después de cargar
    }

    // Recuperar las fechas de vacaciones del empleado desde el backend
    try {
      const response = await fetch(
        `/api/get-employee-vacations/${employee._id}`,
      )
      if (response.ok) {
        const data = await response.json()
        console.log('data vacaciones', data)
        const vacationDates = data.employee.vacaciones || []
        setEmployeeVacationDates(vacationDates)
        setEmployeeVacationDatesVisual(
          vacationDates.sort((a, b) => moment(a) - moment(b)),
        )

        // Mantener las fechas ya seleccionadas y agregar las fechas de vacaciones
        setSelectedVacationDays((prevSelected) => [
          ...prevSelected,
          ...vacationDates.map((date) => new Date(date)),
        ])
      } else {
        Swal.close() // Cerrar Swal después de cargar
        console.error(
          'Error al obtener las fechas de vacaciones del empleado:',
          response.statusText,
        )
      }
    } catch (error) {
      Swal.close() // Cerrar Swal después de cargar
      console.error('Error al realizar la solicitud al backend:', error)
    }

    try {
      const response = await fetch(`/api/get-employee-details/${employee._id}`)
      if (response.ok) {
        const data = await response.json()
        const vacacionesExtra = data.employee.extraDays || ''
        const vacacionesPorLey = data.employee.totalDays || ''
        const vacacionesAlDia = data.employee.availableDays || ''
        setDiasTotalesLeyVacaciones(vacacionesPorLey)
        setDiasTotalesDisponibles(vacacionesAlDia)
        setVacacionesExtraHook(vacacionesExtra)
        console.log('DATAAA total empleado', data)
      } else {
        console.error(
          'Error al obtener detalles del empleado:',
          response.statusText,
        )
      }
      Swal.close() // Cerrar Swal después de cargar
    } catch (error) {
      Swal.close() // Cerrar Swal después de cargar
      console.error('Error al realizar la solicitud al backend:', error)
    }
    Swal.close() // Cerrar Swal después de cargar
    setOpenNewModal(true)
  }

  const handleCloseNewModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenNewModal(false)
    }
    setDiasGanados(0)

    setSelectedVacationDays([])
    setExistingVacationDates([]) // Restablecer las fechas existentes
    setSelectedVacationDaysNews([])
  }

  const handleDeleteVacationDate = (date) => {
    // Filtrar las fechas seleccionadas y actualizar el estado
    const updatedSelectedVacationDays = selectedVacationDays.filter(
      (selectedDate) => !moment(selectedDate).isSame(date, 'day'),
    )
    setSelectedVacationDays(updatedSelectedVacationDays)
  }

  const handleSaveVacations = async () => {
    const formattedVacationDays = selectedVacationDays.map((date) =>
      new Date(date).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }),
    )

    if (selectedEmployee) {
      const updatedEmployeeData = {
        ...selectedEmployee,
        vacaciones: formattedVacationDays,
      }

      const updatedEmployeeDataDias = {
        extraDays: Number(diasGanados) + Number(vacacionesExtraHook),
        availableDays: Number(diasTotalesDisponibles) + Number(diasGanados),
      }

      const employeeId = selectedEmployee._id.toString()

      try {
        const response = await fetch(
          `/api/update-employee-vacations/${employeeId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedEmployeeData),
          },
        )

        const response2 = await fetch(`/api/update-employee/${employeeId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedEmployeeDataDias),
        })

        if (response.ok && response2.ok) {
          console.log('La solicitud al backend fue exitosa')

          // Cierra el modal antes de mostrar el SweetAlert
          handleCloseNewModal()

          // Muestra el SweetAlert de éxito con botón "OK"
          await MySwal.fire({
            icon: 'success',
            title: 'Vacaciones actualizadas correctamente',
            showConfirmButton: true,
          })

          // Recargar la lista de empleados después de la edición (opcional)
          fetchEmployeeData()
        } else {
          console.error('Error al actualizar el empleado:', response.statusText)
          throw new Error('Error al actualizar el empleado')
        }
      } catch (error) {
        console.error('Error al realizar la solicitud al backend:', error)

        // Muestra el SweetAlert de error con botón "OK"
        await MySwal.fire({
          icon: 'error',
          title: 'Error al actualizar el empleado',
          showConfirmButton: true,
        })

        console.log('After fetching data from the backend')
      }
    }
  }

  const handleDelete = async (e, employeeId) => {
    e.stopPropagation() // Evitar que el evento de clic llegue al contenedor y active handleOpen

    const confirmDelete = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará al empleado permanentemente.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    })

    if (confirmDelete.isConfirmed) {
      try {
        const response = await fetch(`/api/delete-employee/${employeeId}`, {
          method: 'DELETE',
        })

        if (response.ok) {
          console.log('Empleado eliminado exitosamente')

          // Recargar la lista de empleados después de la eliminación
          fetchEmployeeData()

          // Mostrar SweetAlert de éxito con botón "OK"
          await MySwal.fire({
            icon: 'success',
            title: 'Empleado eliminado correctamente',
            showConfirmButton: true,
            confirmButtonText: 'OK',
          })
        } else {
          console.error('Error al eliminar el empleado:', response.statusText)
        }
      } catch (error) {
        console.error('Error al realizar la solicitud al backend:', error)
      }
    }
  }

  const [open, setOpen] = React.useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [editedEmployeeData, setEditedEmployeeData] = useState({
    employeeNumber: '',
    name: '',
    // Agrega más campos según sea necesario
  })

  const [employeeCount, setEmployeeCount] = useState(null)
  const [employeeList, setEmployeeList] = useState(null)

  const fetchEmployeeData = async () => {
    try {
      // Obtener la cantidad de empleados
      const countResponse = await fetch('/api/inicio')
      const countData = await countResponse.json()
      setEmployeeCount(countData.employeeCount)

      // Obtener la lista de empleados filtrados por el ID del gerente
      const gerenteId = JSON.parse(localStorage.getItem('gerente')).id
      const listResponse = await fetch(`/api/empleados?gerenteId=${gerenteId}`)
      const listData = await listResponse.json()
      setEmployeeList(listData.employees)
      console.log('LISTT DATAA', listData)
    } catch (error) {
      console.error('Error al obtener la información de inicio:', error)
    }
  }

  useEffect(() => {
    fetchEmployeeData()
  }, [])

  moment.locale('es') // Configura moment para usar español

  const handleOpen = (employee) => {
    setSelectedEmployee(employee)
    setEditedEmployeeData({
      employeeNumber: employee.employeeNumber,
      name: employee.name,
      centerNumber: employee.centerNumber,
      registerDate: employee.hireDate,
      totalDays: employee.totalDays,
      // Agrega más campos según sea necesario
    })
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
      setSelectedEmployee(null)
      setEditedEmployeeData({
        employeeNumber: '',
        name: '',
      })
      setEstadoEdicionEmpleado(true)
    }
  }

  const isDateDisabled = (date) => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    return date < currentDate
  }

  const handleEdit = async () => {
    if (selectedEmployee) {
      const updatedEmployeeData = {
        employeeNumber: editedEmployeeData.employeeNumber,
        name: editedEmployeeData.name,
        centerNumber: editedEmployeeData.centerNumber,
        hireDate: editedEmployeeData.registerDate,
        totalDays: editedEmployeeData.totalDays,
        // Asegúrate de incluir todos los campos necesarios
      }

      const employeeId = selectedEmployee._id.toString()

      try {
        const response = await fetch(`/api/update-employee/${employeeId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedEmployeeData),
        })

        if (response.ok) {
          console.log('La solicitud al backend fue exitosa')

          // Cierra el modal antes de mostrar el SweetAlert
          handleClose()

          // Muestra el SweetAlert de éxito con botón "OK"
          await MySwal.fire({
            icon: 'success',
            title: 'Empleado editado correctamente',
            showConfirmButton: true,
          })

          // Recargar la lista de empleados después de la edición (opcional)
          fetchEmployeeData()
        } else {
          console.error('Error al editar el empleado:', response.statusText)
          throw new Error('Error al editar el empleado')
        }
      } catch (error) {
        console.error('Error al realizar la solicitud al backend:', error)

        // Muestra el SweetAlert de error con botón "OK"
        await MySwal.fire({
          icon: 'error',
          title: 'Error al editar el empleado',
          showConfirmButton: true,
        })
      }
    }
  }

  return (
    <div>
      <Grid xs={12} className="contenedorPadreEmpleados">
        <Grid
          container
          item
          xs={12}
          spacing={4}
          style={{
            padding: '0px 0px 30px 30px',
            backgroundColor: '#fff',
            marginTop: '0px',
          }}
        >
          <Grid
            container
            className="contenedorListaEmpleados contenedorPrincipalApp"
            item
            xs={12}
          >
            {!employeeList && (
              <Grid item xs={6}>
                <h3>Servicio no disponible:</h3>
              </Grid>
            )}
            {employeeList && (
              <div>
                <Grid container item xs={12} style={{ marginTop: '60px' }}>
                  <Grid item xs={6}>
                    <h3>Listado de Colaboradores:</h3>
                  </Grid>
                  <Grid className="contenedorBotonCrearEmpleado" item xs={6}>
                    <Link to="/crear-empleado">
                      <Button variant="contained" color="primary">
                        Agregar Colaborador
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <br />
                <br />
                <Grid container xs={12}>
                  <Grid item className="menuTitulosEmp" xs={12} md={3}>
                    <h5>Colaborador</h5>
                  </Grid>
                  <Grid item className="menuTitulosEmp" xs={12} md={2}>
                    <h5>Días Disponibles</h5>
                  </Grid>
                  <Grid item className="menuTitulosEmp" xs={12} md={2}>
                    <h5>Días por ley</h5>
                  </Grid>
                  <Grid item className="menuTitulosEmp" xs={12} md={2}>
                    <h5>Fecha de ingreso</h5>
                  </Grid>
                  <Grid item className="menuTitulosEmp" xs={12} md={1}>
                    <h5>Status</h5>
                  </Grid>
                  <Grid
                    item
                    className="menuTitulosEmp"
                    xs={12}
                    md={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <h5>Acciones</h5>
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  {employeeList.map((employee) => (
                    <Grid
                      item
                      xs={12}
                      className="cajaEmpleado"
                      key={employee._id}
                    >
                      <div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openDropDown}
                          onClose={handleCloseDropDown}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <hr
                            style={{
                              border: 'solid 1px #eaeaea',
                            }}
                          ></hr>
                        </Menu>
                        {/* <FontAwesomeIcon
                            icon={faHome}
                            onClick={() => handleOpenHomeOfficeModal(employee)}
                            style={{
                              marginRight: '10px',
                              cursor: 'pointer',
                              opacity: 0.6,
                              color: '#1976d2',
                            }}
                          /> */}
                        <Grid container xs={12}>
                          <Grid item xs={12} md={3}>
                            {`${employee.employeeNumber} - ${employee.name}`}{' '}
                          </Grid>
                          <Grid item md={2}>
                            <h5>
                              {employee.availableDays -
                                (employee.vacaciones &&
                                Array.isArray(employee.vacaciones)
                                  ? Number(employee.vacaciones.length)
                                  : 0)}{' '}
                            </h5>
                          </Grid>
                          <Grid item md={2}>
                            <h5>{employee.totalDays}</h5>
                          </Grid>
                          <Grid item md={2}>
                            <h5>
                              {moment(employee.hireDate, 'YYYY-MM-DD').format(
                                'DD-MMM-YYYY',
                              )}
                            </h5>
                          </Grid>
                          <Grid item md={2}>
                            {noVacacionesHoy ? (
                              employee.vacaciones &&
                              employee.vacaciones.some((date) =>
                                moment(date).isSame(moment(), 'day'),
                              ) ? (
                                <h5 className="statusVacONoVac statusVacONoVacPresente">
                                  De vacaciones
                                </h5>
                              ) : (
                                <h5 className="statusVacONoVac">Presente</h5>
                              )
                            ) : (
                              <h5 className="statusVacONoVac">Presente</h5>
                            )}
                          </Grid>
                          <Grid
                            item
                            md={1}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {/* <EventNoteIcon
                              onClick={toggleDrawer('right', true)}
                              style={{
                                cursor: 'pointer',
                                marginRight: '15px',
                                opacity: 0.6,
                                color: '#1976d2',
                              }}
                            /> */}
                            <EventNoteIcon
                              onClick={() => handleOpenNewModal(employee)}
                              style={{
                                cursor: 'pointer',
                                marginRight: '15px',
                                opacity: 0.6,
                                color: '#1976d2',
                              }}
                            />

                            <MenuItem
                              className="menuItemTresPuntos"
                              onClick={() => {
                                handleOpen(employee)
                                handleCloseDropDown()
                              }}
                            >
                              {' '}
                              <DriveFileRenameOutlineIcon />
                            </MenuItem>
                            <MenuItem
                              className="menuItemTresPuntos"
                              onClick={(e) => {
                                handleDelete(e, employee._id)
                                handleCloseDropDown()
                              }}
                            >
                              <DeleteOutlinedIcon
                                style={{
                                  color: '#c53636',
                                }}
                              />
                            </MenuItem>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
            {/* MODAL DE AGENDAR VACACIONES */}
            <Drawer
              anchor={'right'}
              open={state['right']}
              onClose={toggleDrawer('right', false)}
              ModalProps={{
                disableBackdropClick: true, // Evita el cierre al hacer clic fuera del Drawer
              }}
            >
              {list('right')}
            </Drawer>
            <Modal
              disableEscapeKeyDown
              open={openNewModal}
              onClose={handleCloseNewModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <h3>{selectedEmployee && selectedEmployee.name}</h3>
                <Grid container xs={12}>
                  <Grid item xs={12} md={4}>
                    <br />
                    <Grid container xs={12}>
                      <span className="textoVacExtr">
                        Vacaciones Asignadas Extra:
                      </span>
                      <b>
                        <Grid
                          item
                          className="contenedorDiasExtrasCantidad"
                          xs={12}
                        >
                          <h4 className="diasExtrasVacaciones">
                            {vacacionesExtraHook != 0 ? vacacionesExtraHook : 0}
                          </h4>
                        </Grid>
                      </b>
                    </Grid>
                    <br></br>
                    <Grid container xs={12}>
                      <span className="textoVacExtr">Vacaciones por ley:</span>
                      <b>
                        <Grid
                          item
                          className="contenedorDiasExtrasCantidad"
                          xs={12}
                        >
                          <h4 className="diasExtrasVacaciones">
                            {diasTotalesLeyVacaciones > 0
                              ? diasTotalesLeyVacaciones
                              : 0}
                          </h4>
                        </Grid>
                      </b>
                    </Grid>
                    <br></br>
                    <Grid container xs={12}>
                      <span className="textoVacExtr">Vacaciones Totales:</span>
                      <b>
                        <Grid
                          item
                          className="contenedorDiasExtrasCantidad"
                          xs={12}
                        >
                          <h4 className="diasExtrasVacaciones">
                            {Number(diasTotalesLeyVacaciones) +
                              Number(vacacionesExtraHook) -
                              Number(employeeVacationDatesVisual.length)}
                          </h4>
                        </Grid>
                      </b>
                    </Grid>
                    <br></br>
                    <br></br>
                    <div style={{ marginBottom: '20px' }}>
                      <Typography variant="body1" gutterBottom>
                        Selecciona las nuevas fechas de vacaciones:
                      </Typography>
                      <Calendar
                        value={selectedVacationDays}
                        onChange={(value) => {
                          const unselectableDates = employeeVacationDates.map(
                            (date) => +new Date(date),
                          )

                          // Filtrar solo las fechas de la base de datos
                          const databaseDates = selectedVacationDays.filter(
                            (date) => unselectableDates.includes(+date),
                          )

                          // Filtrar las nuevas fechas seleccionadas
                          const newSelectableDates = value.filter(
                            (date) =>
                              !unselectableDates.includes(+date) &&
                              !isDateDisabled(date),
                          )

                          const newSelectableDatesVisuales =
                            newSelectableDates.map((date) => +new Date(date))
                          setSelectedVacationDaysNews(
                            newSelectableDatesVisuales.sort(
                              (a, b) => moment(a) - moment(b),
                            ),
                          )

                          // Actualizar el estado solo con las fechas de la base de datos y las nuevas fechas seleccionadas

                          setSelectedVacationDays([
                            ...databaseDates,
                            ...newSelectableDates,
                          ])
                        }}
                        multiple
                        defaultValue={employeeVacationDates.map((date) =>
                          moment(new Date(date)).format('DD/MM/YYYY'),
                        )}
                        isDateDisabled={isDateDisabled}
                        mapDays={({ date, view }) => {
                          let props = {}
                          if (date < new Date()) {
                            props.className = 'highlight highlight-green' // Puedes ajustar la clase según tus necesidades
                          }

                          return props
                        }}
                      />
                      <br></br>
                      <div>
                        <p>Agregar dias extra de vacaciones</p>
                        <Grid container xs={12}>
                          <b>
                            <Grid item className="" xs={12}>
                              <TextField
                                type="number"
                                className=""
                                variant="outlined"
                                size="small"
                                value={diasGanados}
                                onChange={handleChange}
                                InputProps={{ readOnly: true }}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDecrement}
                                style={{ marginRight: '10px' }}
                              >
                                -
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleIncrement}
                              >
                                +
                              </Button>
                            </Grid>
                          </b>
                        </Grid>
                      </div>
                      <br></br>
                      <Grid container justify="center">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <span className="textoVacExtr">
                            Despues de estos cambios quedan:
                          </span>
                          <br></br>
                          <Grid
                            item
                            className="contenedorDiasExtrasCantidad diasExtrasVacaciones diasExtrasVacacionesTotalActual"
                            xs={12}
                          >
                            <h4 className="">
                              {Number(diasTotalesLeyVacaciones) +
                                Number(vacacionesExtraHook) -
                                Number(selectedVacationDays.length) +
                                Number(diasGanados)}
                            </h4>
                          </Grid>
                          <h5
                            style={{
                              marginLeft: '4px',
                            }}
                          >
                            Dias
                          </h5>
                        </div>
                      </Grid>
                      <br></br>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div style={{ marginTop: '10px' }}>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography variant="body1" gutterBottom>
                            <strong>Nuevos dias seleccionados:</strong>
                          </Typography>

                          {selectedVacationDaysNews.map((date) => {
                            return (
                              <div
                                style={{ display: 'flex', marginBottom: '5px' }}
                              >
                                <div className="contenedorWarning">
                                  {employeeList.some((employee) =>
                                    employee.vacaciones?.some(
                                      (vacacion) =>
                                        moment(vacacion).format(
                                          'YYYY-MM-DD',
                                        ) === moment(date).format('YYYY-MM-DD'),
                                    ),
                                  ) && (
                                    <Tooltip
                                      title={
                                        <>
                                          <div>
                                            <h2>
                                              Las siguientes personas tienen
                                              vacaciones el mismo día:{' '}
                                              {moment(date).format(
                                                'DD/MM/YYYY',
                                              )}
                                            </h2>
                                            <br />
                                          </div>
                                          {employeeList
                                            .filter((employee) =>
                                              employee.vacaciones?.some(
                                                (vacacion) =>
                                                  moment(vacacion).format(
                                                    'YYYY-MM-DD',
                                                  ) ===
                                                  moment(date).format(
                                                    'YYYY-MM-DD',
                                                  ),
                                              ),
                                            )
                                            .map((employee) => (
                                              <div key={employee._id}>
                                                <h2>
                                                  <p>- {employee.name}</p>
                                                </h2>
                                              </div>
                                            ))}
                                        </>
                                      }
                                    >
                                      <span style={{ marginLeft: '5px' }}>
                                        <FontAwesomeIcon
                                          icon={faExclamationTriangle}
                                          style={{
                                            color: 'orange',
                                            fontSize: '24px',
                                            cursor: 'pointer',
                                            marginTop: '3px',
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  )}
                                </div>
                                <span
                                  style={{
                                    display: 'flex',
                                    width: '120px',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    backgroundColor: '#1976d2',
                                    border: 'solid 1px',
                                    padding: '3px 10px',
                                    color: '#fff',
                                  }}
                                >
                                  {moment(date).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            )
                          })}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1" gutterBottom>
                            <strong>Vacaciones agendadas pendientes:</strong>
                          </Typography>
                          {employeeVacationDates
                            .filter((date) =>
                              moment(date).isSameOrAfter(moment(), 'day'),
                            )
                            .map((date) => {
                              let hasEmployeesWithSameDate
                              if (selectedEmployee && selectedEmployee._id) {
                                hasEmployeesWithSameDate = employeeList.some(
                                  (employee) =>
                                    employee._id !== selectedEmployee._id &&
                                    employee.vacaciones?.some(
                                      (vacacion) =>
                                        moment(vacacion).format(
                                          'YYYY-MM-DD',
                                        ) === moment(date).format('YYYY-MM-DD'),
                                    ),
                                )
                                // Resto del código...
                              } else {
                                console.error(
                                  'selectedEmployee no está definido o no tiene _id',
                                )
                                // Manejar el caso donde selectedEmployee no está definido
                              }

                              return (
                                <div
                                  key={date}
                                  style={{
                                    display: 'flex',
                                    marginBottom: '5px',
                                  }}
                                >
                                  <div className="contenedorWarning">
                                    {hasEmployeesWithSameDate && (
                                      <Tooltip
                                        title={
                                          <>
                                            <div>
                                              <h2>
                                                Las siguientes personas tienen
                                                vacaciones el mismo día:{' '}
                                                {moment(date).format(
                                                  'DD/MM/YYYY',
                                                )}
                                              </h2>
                                              <br />
                                            </div>
                                            {employeeList
                                              .filter((employee) =>
                                                employee.vacaciones?.some(
                                                  (vacacion) =>
                                                    moment(vacacion).format(
                                                      'YYYY-MM-DD',
                                                    ) ===
                                                    moment(date).format(
                                                      'YYYY-MM-DD',
                                                    ),
                                                ),
                                              )
                                              .map((employee) => {
                                                // Agrega la condición para evitar mostrar el nombre del empleado cargado
                                                if (
                                                  employee._id !==
                                                  selectedEmployee._id
                                                ) {
                                                  return (
                                                    <div key={employee._id}>
                                                      <h2>
                                                        <p>- {employee.name}</p>
                                                      </h2>
                                                    </div>
                                                  )
                                                }
                                                return null
                                              })}
                                          </>
                                        }
                                      >
                                        <span style={{ marginLeft: '5px' }}>
                                          <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            style={{
                                              color: 'orange',
                                              marginRight: '10px',
                                              fontSize: '24px',
                                              cursor: 'pointer',
                                              marginTop: '3px',
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                  <span
                                    style={{
                                      display: 'flex',
                                      width: '120px',
                                      alignItems: 'center',
                                      borderRadius: '5px',
                                      backgroundColor: '#1976d2',
                                      border: 'solid 1px',
                                      padding: '3px 10px',
                                      color: '#fff',
                                    }}
                                  >
                                    {moment(date).format('DD/MM/YYYY')}
                                  </span>
                                  <ClearIcon
                                    style={{
                                      marginLeft: '10px',
                                      cursor: 'pointer',
                                      marginTop: '4px',
                                    }}
                                    onClick={() => {
                                      handleDeleteVacationDate(date)
                                      // Elimina la fecha del estado empleadoVacationDates
                                      const updatedEmployeeVacationDates =
                                        employeeVacationDates.filter(
                                          (selectedDate) =>
                                            !moment(selectedDate).isSame(
                                              date,
                                              'day',
                                            ),
                                        )
                                      setEmployeeVacationDates(
                                        updatedEmployeeVacationDates,
                                      )
                                    }}
                                  />
                                </div>
                              )
                            })}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1" gutterBottom>
                            <strong>Vacaciones agendadas tomadas:</strong>
                          </Typography>
                          {employeeVacationDates
                            .filter((date) =>
                              moment(date).isBefore(moment(), 'day'),
                            )
                            .map((date) => (
                              <div
                                key={date}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '5px',
                                  marginBottom: '5px',
                                  borderRadius: '5px',
                                }}
                              >
                                {moment(date).format('DD/MM/YYYY')}
                              </div>
                            ))}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container xs={12} justifyContent="flex-end">
                  <Button
                    onClick={handleSaveVacations}
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                  <Button
                    onClick={handleCloseNewModal}
                    style={{ marginLeft: '10px' }}
                    variant="outlined"
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Box>
            </Modal>

            <Modal
              open={open}
              onClose={handleClose}
              disableEscapeKeyDown
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Editar Empleado
                </Typography>
                <br></br>
                <Button
                  onClick={handleEditarEmpleado}
                  variant="contained"
                  color="primary"
                >
                  Click para activar edición
                </Button>
                <br></br>
                <br></br>
                {selectedEmployee && (
                  <>
                    <TextField
                      fullWidth
                      disabled={estadoEdicionEmpleado}
                      label="Número de Empleado"
                      value={editedEmployeeData.employeeNumber}
                      onChange={(e) =>
                        setEditedEmployeeData((prevData) => ({
                          ...prevData,
                          employeeNumber: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      fullWidth
                      disabled={estadoEdicionEmpleado}
                      label="Fecha de ingreso a la empresa"
                      type="date" // Establece el tipo de input a 'date'
                      value={editedEmployeeData.registerDate}
                      onChange={(e) =>
                        setEditedEmployeeData((prevData) => ({
                          ...prevData,
                          registerDate: e.target.value,
                        }))
                      }
                    />
                    <FormControl fullWidth>
                      <InputLabel htmlFor="centerNumber">
                        Número de Centro
                      </InputLabel>
                      <Select
                        disabled={estadoEdicionEmpleado}
                        fullWidth
                        label="Número de centro"
                        id="centerNumber"
                        name="centerNumber"
                        value={editedEmployeeData.centerNumber}
                        onChange={(e) =>
                          setEditedEmployeeData((prevData) => ({
                            ...prevData,
                            centerNumber: e.target.value,
                          }))
                        }
                      >
                        {centers &&
                          centers.map((center) => (
                            <MenuItem
                              key={center.centerNumber}
                              value={center.centerNumber}
                            >
                              {center.centerNumber}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      disabled={estadoEdicionEmpleado}
                      label="Nombre Completo"
                      value={editedEmployeeData.name}
                      onChange={(e) =>
                        setEditedEmployeeData((prevData) => ({
                          ...prevData,
                          name: e.target.value,
                        }))
                      }
                    />
                    {/* Agrega más campos según sea necesario */}
                    <br></br>
                    <br></br>
                    <Grid container xs={12} justifyContent="flex-end">
                      <Button
                        onClick={handleEdit}
                        variant="contained"
                        color="primary"
                      >
                        Editar
                      </Button>
                      <Button
                        onClick={handleClose}
                        style={{ marginLeft: '10px' }}
                        variant="outlined"
                      >
                        Cerrar
                      </Button>
                    </Grid>
                  </>
                )}
              </Box>
            </Modal>
            <Modal
              open={openHomeOfficeModal}
              onClose={handleCloseHomeOfficeModal}
              disableEscapeKeyDown
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid container xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Calendario de Home Office
                    </Typography>
                    <br />
                    <div style={{ marginBottom: '20px' }}>
                      <Typography variant="body1" gutterBottom>
                        Selecciona las fechas de Home Office:
                      </Typography>
                      <Calendar
                        value={selectedHomeOfficeDays}
                        onChange={(value) => {
                          const unselectableDates = employeeHomeOfficeDates.map(
                            (date) => +new Date(date),
                          )

                          // Filtrar solo las fechas de la base de datos
                          const databaseDates = selectedHomeOfficeDays.filter(
                            (date) => unselectableDates.includes(+date),
                          )

                          // Filtrar las nuevas fechas seleccionadas
                          const newSelectableDates = value.filter(
                            (date) =>
                              !unselectableDates.includes(+date) &&
                              !isDateDisabled(date),
                          )

                          // Actualizar el estado solo con las fechas de la base de datos y las nuevas fechas seleccionadas
                          setSelectedHomeOfficeDays([
                            ...databaseDates,
                            ...newSelectableDates,
                          ])
                        }}
                        multiple
                        defaultValue={employeeHomeOfficeDates.map(
                          (date) => new Date(date),
                        )}
                        isDateDisabled={isDateDisabled}
                        mapDays={({ date, view }) => {
                          let props = {}

                          if (date < new Date()) {
                            props.className = 'highlight highlight-green' // Puedes ajustar la clase según tus necesidades
                          }

                          return props
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div style={{ marginTop: '20px' }}>
                      <Typography variant="h6" gutterBottom>
                        Resumen de fechas:
                      </Typography>
                      {/* <Typography variant="body1" gutterBottom>
                      <strong>Dias seleccionados:</strong>
                    </Typography>
                    {selectedHomeOfficeDays.map((date) => (
                      <div key={date}>{moment(date).format('DD/MM/YYYY')}</div>
                    ))} */}
                      <div style={{ display: 'flex' }}>
                        <Grid container xs={12}>
                          <Grid item xs={6}>
                            <Typography variant="body1" gutterBottom>
                              <strong>Dias de Home Office agendados:</strong>
                            </Typography>
                            {employeeHomeOfficeDates
                              .filter((date) =>
                                moment(date).isSameOrAfter(moment(), 'day'),
                              )
                              .map((date) => (
                                <div
                                  key={date}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '5px',
                                    marginBottom: '5px',
                                    borderRadius: '5px',
                                  }}
                                >
                                  <ClearIcon
                                    style={{
                                      marginRight: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      handleDeleteHomeOfficeDate(date)
                                      // Elimina la fecha del estado empleadoVacationDates
                                      const updatedEmployeeHomeOfficeDates =
                                        employeeHomeOfficeDates.filter(
                                          (selectedDate) =>
                                            !moment(selectedDate).isSame(
                                              date,
                                              'day',
                                            ),
                                        )
                                      setEmployeeHomeOfficeDates(
                                        updatedEmployeeHomeOfficeDates,
                                      )
                                    }}
                                  />
                                  {moment(date).format('DD/MM/YYYY')}
                                </div>
                              ))}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1" gutterBottom>
                              <strong>
                                Dias de Home Office agendados ya tomados:
                              </strong>
                            </Typography>
                            {employeeHomeOfficeDates
                              .filter((date) =>
                                moment(date).isBefore(moment(), 'day'),
                              )
                              .map((date) => (
                                <div
                                  key={date}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '5px',
                                    marginBottom: '5px',
                                    borderRadius: '5px',
                                  }}
                                >
                                  {moment(date).format('DD/MM/YYYY')}
                                </div>
                              ))}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container xs={12} justifyContent="flex-end">
                  <Button
                    onClick={handleSaveHomeOffices}
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                  <Button
                    onClick={handleCloseHomeOfficeModal}
                    style={{ marginLeft: '10px' }}
                    variant="outlined"
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default InicioPage
