import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/es'

const EmployeeForm = () => {
  const navigate = useNavigate()
  const idDelGerente = JSON.parse(localStorage.getItem('gerente')).id
  const [employeeData, setEmployeeData] = useState({
    employeeNumber: '',
    name: '',
    centerNumber: '',
    hireDate: '',
    managerEmployeeNumber: '',
    totalDays: '',
    availableDays: '',
    extraDays: '',
    gerenteId: idDelGerente,
  })

  const [centers, setCenters] = useState([])

  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const response = await fetch('/api/centers')
        const data = await response.json()
        setCenters(data) // Asigna los centros al estado
      } catch (error) {
        console.error('Error al obtener la lista de centros:', error)
      }
    }

    fetchCenters()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setEmployeeData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch('/api/create-employee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...employeeData, // Incluir el resto de los datos del empleado
          gerenteId: idDelGerente,
        }),
      })

      if (response.ok) {
        console.log('Empleado creado exitosamente')

        // Muestra el SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Empleado creado correctamente',
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // Después de crear el empleado con éxito, redirige al usuario a la página de inicio
            navigate('/listado')
          }
        })
      } else {
        console.error('Error al crear el empleado')
      }
    } catch (error) {
      console.error('Error de red:', error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} md={6} xs={12}>
        <Grid container item spacing={2} xs={12}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                id="name"
                type="text"
                name="name"
                value={employeeData.name}
                onChange={handleChange}
                label="Nombre Completo"
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                id="employeeNumber"
                type="text"
                name="employeeNumber"
                value={employeeData.employeeNumber}
                onChange={handleChange}
                label="Número de empleado"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="centerNumber">Número de centro</InputLabel>
            <Select
              required
              fullWidth
              id="centerNumber"
              name="centerNumber"
              label="Número de centro"
              value={employeeData.centerNumber}
              onChange={handleChange}
            >
              {centers.map((center) => (
                <MenuItem key={center.centerNumber} value={center.centerNumber}>
                  {center.centerNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12}>
          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="hireDate">
              Fecha de ingreso a la empresa
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                id="hireDate"
                type="date"
                name="hireDate"
                value={employeeData.hireDate}
                onChange={handleChange}
                inputProps={{
                  max: moment().format('YYYY-MM-DD'), // Opcional: establece la fecha máxima como la fecha actual
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      <Button
        className="botonAgregarNuevoEmpleado"
        type="submit"
        variant="contained"
        color="primary"
      >
        Registrar empleado
      </Button>
    </form>
  )
}

export default EmployeeForm
