import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { TextField, Button, Typography, Container, Paper } from '@mui/material'
import Swal from 'sweetalert2'
import validator from 'validator'

const RegisterPage = ({ setIsAuthenticated }) => {
  const [name, setFullName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [registrationSuccessful, setRegistrationSuccessful] = useState(false)

  const nombreCampo = 'Nombre'
  const correoCampo = 'Correo'
  const contraseñaCampo = 'Contraseña'
  const confirmarContraseñaCampo = 'Confirmación contraseña'

  const handleRegister = async () => {
    // Validar que todos los campos estén completos
    if (!name || !username || !password || !confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: 'Por favor, completa todos los campos.',
      })
      return
    }

    if (name.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: nombreCampo + ' ' + 'debe contener por lo menos 6 caracteres',
      })
      return
    }

    if (username.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: correoCampo + ' ' + 'debe contener por lo menos 6 caracteres',
      })
      return
    }

    if (password.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: contraseñaCampo + ' ' + 'debe contener por lo menos 6 caracteres',
      })
      return
    }

    // Validar que las contraseñas coincidan
    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: 'Las contraseñas no coinciden. Por favor, verifica e inténtalo de nuevo.',
      })
      return
    }

    if (
      !validator.isEmail(username) ||
      !username.trim().endsWith('@coppel.com')
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: 'El correo debe ser un correo valido y de dominio coppel',
      })
      return
    }

    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, username, password }),
      })

      if (response.ok) {
        const data = await response.json()
        // Registro exitoso, puedes redirigir a la página de inicio de sesión o realizar alguna otra acción
        console.log('Registro exitoso')

        // Indicar que el registro fue exitoso
        setRegistrationSuccessful(true)
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso',
          text: 'Bienvenido, has sido registrado correctamente.',
        })
      } else {
        const data = await response.json()
        if (response.status === 400 && data.error === 'El usuario ya existe') {
          Swal.fire({
            icon: 'error',
            title: 'Error de registro',
            text: 'El usuario ya existe. Por favor, elige otro nombre de usuario.',
          })
        } else {
          // Manejar otros errores de registro aquí
          console.error('Error al registrar:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error al enviar la solicitud de registro:', error)
    }
  }

  // Si el registro fue exitoso, redirigir a la página de listado
  if (registrationSuccessful) {
    return <Navigate to="/login" />
  }

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '30px' }}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" mb={2}>
          Registro
        </Typography>
        <Typography component="h6" variant="h6" mb={2}>
          Agendar Vacaciones Coppel
        </Typography>
        <form onSubmit={handleRegister}>
          <TextField
            name={nombreCampo}
            margin="normal"
            required
            fullWidth
            label="Nombre completo"
            value={name}
            onChange={(e) => setFullName(e.target.value)}
          />
          <TextField
            name={correoCampo}
            margin="normal"
            required
            fullWidth
            label="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            name={contraseñaCampo}
            margin="normal"
            required
            fullWidth
            label="Contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            name={confirmarContraseñaCampo}
            margin="normal"
            required
            fullWidth
            label="Confirmar Contraseña"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Button
              variant="contained"
              onClick={handleRegister}
              sx={{ width: '100%', mb: 2 }}
            >
              Registrarse
            </Button>
            <Link to="/login" style={{ textDecoration: 'none', width: '100%' }}>
              <Button variant="outlined" sx={{ width: '100%' }}>
                Iniciar Sesión
              </Button>
            </Link>
          </div>
        </form>
      </Paper>
    </Container>
  )
}

export default RegisterPage
