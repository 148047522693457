import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Button, Container, TextField, Typography, Paper } from '@mui/material'
import { Navigate, Link } from 'react-router-dom'

const LoginPage = ({ setIsAuthenticated, isAuthenticated }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    // Verificar si hay una sesión activa al cargar la página
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true'

    if (isAuthenticated) {
      setIsAuthenticated(true)
    }
  }, [setIsAuthenticated])

  const handleLogin = async () => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })

      if (response.ok) {
        const data = await response.json()
        localStorage.setItem('isAuthenticated', 'true')
        localStorage.setItem('gerente', JSON.stringify(data.gerente))
        setIsAuthenticated(true)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error de inicio de sesión',
          text: 'Usuario y/o contraseña incorrectos',
        })
        console.error('Error al iniciar sesión:', response.statusText)
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error de conexión',
        text: 'No se pudo conectar al servidor',
      })
      console.error('Error al enviar la solicitud de inicio de sesión:', error)
    }
  }

  if (isAuthenticated) {
    return <Navigate to="/listado" />
  }

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '30px' }}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" mb={2}>
          Iniciar Sesión
        </Typography>
        <Typography component="h6" variant="h6" mb={2}>
          Agendar Vacaciones Coppel
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="Usuario"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Contraseña"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{ marginY: 2 }}
        >
          Iniciar Sesión
        </Button>
        <Link to="/registro" style={{ textDecoration: 'none', width: '100%' }}>
          <Button variant="outlined" color="primary" fullWidth>
            Registrarse
          </Button>
        </Link>
      </Paper>
    </Container>
  )
}

export default LoginPage
